/** @jsx jsx */
import { jsx } from "theme-ui";

const sx = {
    heading: {
        fontSize: 6,
        fontWeight: "bold",
        mt: 0,
        mb: 2,
    },
    para: {
        fontSize: 3,
    },
    link: {
        color: "link",
    },
};

function Home(props) {
    return (
        <div {...props}>
            <h1 sx={sx.heading}>Sustainable Development Goal 9.1.1</h1>
            <p sx={sx.para}>
                SDG 9.1.1 is based on the{" "}
                <strong>Rural Access Index (RAI)</strong>, first developed by
                the World Bank in 2006, and is one of the most important global
                development indicators in the transport sector. The RAI is
                defined as the proportion of the rural population who live
                within 2 km of an all-season road.
            </p>
            <p sx={sx.para}>
                This map uses publicly available data to calculate the RAI for
                two countries, Rwanda and Burundi. Three datasets are required
                to calculate the RAI: roads, rural-urban boundaries, and
                population. Road data are sourced from{" "}
                <a
                    sx={sx.link}
                    href="https://www.openstreetmap.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    OpenStreetMap
                </a>
                , rural-urban boundaries are sourced from{" "}
                <a
                    sx={sx.link}
                    href="https://sedac.ciesin.columbia.edu/data/set/grump-v1-urban-ext-polygons-rev01/data-download"
                    target="_blank"
                    rel="noreferrer"
                >
                    GRUMP
                </a>
                , and population data are sourced from{" "}
                <a
                    sx={sx.link}
                    href="https://data.humdata.org/search?organization=facebook&q=%22High%20Resolution%20Population%20Density%20Maps%20%2B%20Demographic%20Estimates%22"
                    target="_blank"
                    rel="noreferrer"
                >
                    Facebook’s High Resolution Population Density Maps
                </a>{" "}
                + Demographic Estimates.
            </p>
            <p sx={sx.para}>
                This map uses Facebook’s gender disaggregated population data to
                quantify how gender impacts road access. It builds off a{" "}
                <a
                    sx={sx.link}
                    href="https://rai.azavea.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    previous effort
                </a>{" "}
                from Azavea to calculate the RAI for all countries globally.
            </p>
            <p sx={sx.para}>
                <em>
                    NOTE: The RAI scale has been inverted in the map, to draw
                    attention to areas <strong>without</strong> road access.
                </em>
            </p>
        </div>
    );
}

export default Home;
