export const COUNTRIES = {
    BDI: {
        name: "Burundi",
        population: 11092588,
        ruralPopulation: 9770594,
        ruralPopulationWomen: 4927085,
        ruralPopulationMen: 4706959,
        withoutAccessWomen: 228072,
        withoutAccessMen: 221492,
        rai: 0.953988018,
        wmm_mean: 0.1727024887,
    },
    RWA: {
        name: "Rwanda",
        population: 11591162,
        ruralPopulation: 10318222,
        ruralPopulationWomen: 5384492,
        ruralPopulationMen: 4933730,
        withoutAccessWomen: 944681,
        withoutAccessMen: 863471,
        rai: 0.8247612654,
        wmm_mean: 0.2866123114,
    },
    UGA: {
        name: "Uganda",
        population: 46506361,
        ruralPopulation: 33078454,
        ruralPopulationWomen: 16976421,
        ruralPopulationMen: 16102034,
        ruralpop2k: 24727512.6179,
        withoutAccessWomen: 4258384,
        withoutAccessMen: 4092558,
        rai: 0.747541345057,
    },
};
