import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./theme";
import "mapbox-gl/dist/mapbox-gl.css";

import MapPage from "./pages/MapPage";

const sx = {
    page: {
        width: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
    },
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <MapPage sx={sx.page} />
        </ThemeProvider>
    );
}

export default App;
