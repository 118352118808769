export const GAP_NAME = "wmm_mean";
export const RAI_NAME = "rai";

export const COLOR_ROADS = "#757A44";

export const RAMP_MORE_MEN = [
    "#deebf7",
    "#9ecae1",
    "#4292c6",
    "#08519c",
    "#08306b",
];
export const RAMP_SAME = [
    "#f0f0f0",
    "#bdbdbd",
    "#969696",
    "#737373",
    "#525252",
];
export const RAMP_MORE_WOMEN = [
    "#ffffe5",
    "#fec44f",
    "#fe9929",
    "#ec7014",
    "#cc4c02",
];
export const RAMP_OFF = ["#f4f4f4", "#f3f3f3", "#f2f2f2", "#f1f1f1", "#f0f0f0"];

export const FILTER_VALUES = Object.freeze({
    ALL_AREAS: "ALL_AREAS",
    WITH_GAP: "WITH_GAP",
    WITH_BIG_GAP: "WITH_BIG_GAP",
});
