/** @jsx jsx */
import { jsx } from "theme-ui";

import { COUNTRIES } from "../../data/countries";

const sx = {
    heading: {
        fontWeight: "bold",
        fontSize: 3,
        mb: 1,
    },
    name: {
        m: 0,
        lineHeight: 1,
        fontSize: "4.8rem",
        fontWeight: "normal",
    },
    stats: {
        mt: 2,
        p: 0,
        fontSize: 3,
    },
    stat: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        py: 1,
    },
    label: {
        fontWeight: "bold",
    },
    value: {
        textAlign: "right",
    },
};

function Stat({ label, value, secondary, line, ...props }) {
    const formattedValue = Number.isInteger(value)
        ? value.toLocaleString()
        : `${Math.round(value * 100000) / 1000}%`;

    return (
        <li
            sx={{
                ...sx.stat,
                ...(line
                    ? {
                          borderBottom: "1px solid transparent",
                          borderBottomColor: "divider",
                      }
                    : {
                          pb: 0,
                      }),
            }}
        >
            <span
                sx={{ ...sx.label, fontWeight: secondary ? "normal" : "bold" }}
            >
                {label}
            </span>
            <span sx={sx.value}>{formattedValue}</span>
        </li>
    );
}

function Country({ country, ...props }) {
    const {
        name,
        population,
        ruralPopulation,
        ruralPopulationWomen,
        ruralPopulationMen,
        withoutAccessWomen,
        withoutAccessMen,
        rai,
    } = COUNTRIES[country];

    return (
        <div {...props}>
            <h1 sx={sx.heading}>Sustainable Development Goal 9.1.1</h1>
            <h2 sx={sx.name}>{name}</h2>
            <ul sx={sx.stats}>
                <Stat label="Total population" value={population} line />
                <Stat label="Rural population" value={ruralPopulation} />
                <Stat
                    label="with road access (RAI)"
                    value={rai}
                    secondary
                    line
                />
                <Stat label="Rural women" value={ruralPopulationWomen} />
                <Stat
                    label="with road access (RAI)"
                    value={1 - withoutAccessWomen / ruralPopulationWomen}
                    secondary
                    line
                />
                <Stat label="Rural men" value={ruralPopulationMen} />
                <Stat
                    label="with road access (RAI)"
                    value={1 - withoutAccessMen / ruralPopulationMen}
                    secondary
                />
            </ul>
        </div>
    );
}

export default Country;
