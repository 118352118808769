/** @jsx jsx */
import { jsx, Flex, Link, Image } from "theme-ui";
import { useState, useRef, useEffect, useCallback } from "react";
import ReactMapGL, {
    Source,
    Layer,
    FlyToInterpolator,
    WebMercatorViewport,
    TRANSITION_EVENTS,
} from "react-map-gl";

import Tooltip from "./Tooltip";
import usePrevious from "../hooks/usePrevious";

import logo from "../img/azavea-color-full.svg";

import {
    GAP_NAME,
    RAI_NAME,
    COLOR_ROADS,
    RAMP_MORE_MEN,
    RAMP_SAME,
    RAMP_MORE_WOMEN,
    FILTER_VALUES,
} from "../constants";

import HOME_COUNTRIES from "../data/HOME_Countries.json";
import BURUNDI_AREAS from "../data/BDI_Admin_Level_2_FINAL.json";
import BURUNDI_ROADS from "../data/BDI_OSM_Road_FINAL.json";
import RWANDA_AREAS from "../data/RWA_Admin_Level_3_FINAL.json";
import RWANDA_ROADS from "../data/RWA_OSM_Road_FINAL.json";
import UGANDA_AREAS from "../data/UGA_Admin_Level_3_FINAL.json";
import UGANDA_ROADS from "../data/UGA_OSM_Road_FINAL.json";

const ALL_AREAS = {
    type: "FeatureCollection",
    name: "all_areas",
    features: [
        ...BURUNDI_AREAS.features,
        ...RWANDA_AREAS.features,
        ...UGANDA_AREAS.features,
    ],
};

const ALL_ROADS = {
    type: "FeatureCollection",
    name: "all_roads",
    features: [
        ...BURUNDI_ROADS.features,
        ...RWANDA_ROADS.features,
        ...UGANDA_ROADS.features,
    ],
};

const MAP_BOUNDS = {
    minLongitude: 29.5,
    minLatitude: -4,
    maxLongitude: 34.5,
    maxLatitude: 3.7,
};

const COUNTRIES = {
    home: {
        centroid: {
            latitude: -6.1,
            longitude: 34.5,
        },
        bounds: MAP_BOUNDS,
    },
    BDI: {
        centroid: {
            latitude: -3.3,
            longitude: 30,
        },
        bounds: {
            minLongitude: 29.018,
            minLatitude: -4.455,
            maxLongitude: 31.783,
            maxLatitude: -2.348,
        },
    },
    RWA: {
        centroid: {
            latitude: -2,
            longitude: 30,
        },
        bounds: {
            minLongitude: 28.867,
            minLatitude: -3.317,
            maxLongitude: 31.783,
            maxLatitude: -1.2,
        },
    },
    UGA: {
        centroid: {
            latitude: 1,
            longitude: 32,
        },
        bounds: {
            minLongitude: 29.467,
            minLatitude: -1.483,
            maxLongitude: 34.967,
            maxLatitude: 4.55,
        },
    },
};

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const PAINT_FILL = {
    "fill-color": [
        "let",
        "ramp",
        [
            "step",
            ["get", GAP_NAME],
            ["literal", RAMP_MORE_MEN],
            -0.2,
            ["literal", RAMP_SAME],
            0.2,
            ["literal", RAMP_MORE_WOMEN],
        ],
        "index",
        ["step", ["get", RAI_NAME], 4, 0.25, 3, 0.5, 2, 0.75, 1, 0.9, 0],
        ["to-color", ["at", ["var", "index"], ["var", "ramp"]]],
    ],
};

const PAINT_LINE = {
    "line-color": "#000",
    "line-opacity": ["case", ["==", ["feature-state", "hover"], true], 1, 0],
    "line-width": 2,
};

const PAINT_ROADS = {
    "line-color": COLOR_ROADS,
    "line-width": [
        "match",
        ["get", "highway"],
        "trunk",
        1.5,
        "primary",
        1.5,
        "secondary",
        0.5,
        "tertiary",
        0.25,
        0.25,
    ],
    "line-opacity": 0.2,
};

const FILTER_ALL = ["any", true];

const FILTER_WITH_GAP = [
    "any",
    ["<", ["get", GAP_NAME], -0.2],
    [">", ["get", GAP_NAME], 0.2],
];

const FILTER_WITH_BIG_GAP = [
    "any",
    ["<", ["get", GAP_NAME], -3],
    [">", ["get", GAP_NAME], 1],
];

const getCursor = ({ isDragging, isHovering }) =>
    isDragging ? "grabbing" : "grab";

const sx = {
    map: {
        position: "relative",
        flexDirection: "column",
    },
    link: {
        position: "absolute",
        bottom: 3,
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        opacity: 0.3,
        filter: "saturate(0) brightness(0)",
        "&:hover": {
            opacity: 1,
            filter: "initial",
        },
        "&:focus": {
            opacity: 1,
            filter: "initial",
        },
    },
    logo: {
        width: "auto",
        height: "2.4rem",
    },
};

function Map({ view = "home", filter, ...props }) {
    const mapRef = useRef();
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [firstSymbolLayerId, setFirstSymbolLayerId] = useState();

    const [hoveredFeature, setHoveredFeature] = useState();
    const [mouseCoords, setMouseCoords] = useState();

    const [viewport, setViewport] = useState({
        width: "100%",
        height: "100%",
        zoom: 6,
        ...COUNTRIES.home.centroid,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const fitViewPortTo = useCallback(
        (view) => {
            setIsTransitioning(true);
            const { width, height } = dimensions;
            const {
                minLatitude,
                minLongitude,
                maxLatitude,
                maxLongitude,
            } = COUNTRIES[view].bounds;
            const fitViewport = new WebMercatorViewport({
                width,
                height,
            }).fitBounds(
                [
                    [minLongitude, minLatitude],
                    [maxLongitude, maxLatitude],
                ],
                { padding: 60 }
            );
            setViewport(fitViewport);
        },
        [dimensions]
    );

    const handleLoad = () => {
        let layers = mapRef.current.getMap().getStyle().layers;
        for (let i = 0; i < layers.length; i++) {
            if (layers[i].type === "symbol") {
                setFirstSymbolLayerId(layers[i].id);
                break;
            }
        }

        const {
            width,
            height,
        } = mapRef.current.getMap().getContainer().getBoundingClientRect();
        setDimensions({ width, height });

        fitViewPortTo("home");
    };

    const previousView = usePrevious(view);
    useEffect(() => {
        if (
            !view ||
            !previousView ||
            !mapRef.current ||
            !dimensions?.width ||
            !dimensions?.height
        ) {
            return;
        }
        if (previousView !== view) {
            fitViewPortTo(view);
        }
    }, [view, previousView, dimensions, fitViewPortTo]);

    const handleHover = (event) => {
        const {
            features,
            srcEvent: { offsetX, offsetY },
            leftButton,
        } = event;

        const feature = leftButton ? null : features?.[0];

        if (hoveredFeature && hoveredFeature.id !== feature?.id) {
            mapRef.current.getMap().setFeatureState(
                {
                    source: "areas",
                    id: hoveredFeature.id,
                },
                {
                    hover: false,
                }
            );
        }

        if (feature) {
            mapRef.current.getMap().setFeatureState(
                {
                    source: "areas",
                    id: feature.id,
                },
                {
                    hover: true,
                }
            );
        }

        setHoveredFeature(feature);
        setMouseCoords({ x: offsetX, y: offsetY });
    };

    const handleViewStateChange = ({ viewState }) => {
        let { latitude, longitude, width, height } = viewState;

        if (latitude < MAP_BOUNDS.minLatitude) {
            latitude = MAP_BOUNDS.minLatitude;
        } else if (latitude > MAP_BOUNDS.maxLatitude) {
            latitude = MAP_BOUNDS.maxLatitude;
        }

        if (longitude < MAP_BOUNDS.minLongitude) {
            longitude = MAP_BOUNDS.minLongitude;
        } else if (longitude > MAP_BOUNDS.maxLongitude) {
            longitude = MAP_BOUNDS.maxLongitude;
        }

        if (width !== dimensions.width || height !== dimensions.height) {
            setDimensions({ width, height });
        }

        setViewport({ ...viewState, longitude, latitude });
    };

    const activeFilter =
        filter === FILTER_VALUES.WITH_GAP
            ? FILTER_WITH_GAP
            : filter === FILTER_VALUES.WITH_BIG_GAP
            ? FILTER_WITH_BIG_GAP
            : FILTER_ALL;

    return (
        <Flex sx={sx.map} {...props}>
            <ReactMapGL
                {...viewport}
                width="100%"
                height="100%"
                minZoom={6}
                maxZoom={9}
                ref={mapRef}
                onLoad={handleLoad}
                onViewStateChange={handleViewStateChange}
                mapStyle="mapbox://styles/azavea/ckhxjy06u0wkz1ampzd7k5b54"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                transitionInterruption={TRANSITION_EVENTS.UPDATE}
                transitionDuration={isTransitioning ? "auto" : null}
                transitionInterpolator={
                    isTransitioning ? new FlyToInterpolator() : null
                }
                onTransitionEnd={() => setIsTransitioning(false)}
                interactiveLayerIds={["fills"]}
                getCursor={getCursor}
                onHover={handleHover}
                mapOptions={{ logoPosition: "bottom-right" }}
            >
                <Source type="geojson" data={ALL_AREAS} id="areas" generateId>
                    <Layer
                        key="fills"
                        id="fills"
                        type="fill"
                        paint={PAINT_FILL}
                        filter={activeFilter}
                        beforeId={firstSymbolLayerId}
                    />
                    <Layer
                        key="strokes"
                        id="strokes"
                        type="line"
                        paint={PAINT_LINE}
                        filter={activeFilter}
                        beforeId={firstSymbolLayerId}
                    />
                </Source>
                <Source type="geojson" data={ALL_ROADS}>
                    <Layer
                        key="roads"
                        type="line"
                        paint={PAINT_ROADS}
                        id="roads"
                        beforeId={firstSymbolLayerId}
                    />
                </Source>
                <Source type="geojson" data={HOME_COUNTRIES}>
                    <Layer
                        type="line"
                        paint={{
                            "line-width": 2,
                            "line-color": "#7B7E62",
                        }}
                        id="countries"
                        beforeId="strokes"
                    />
                </Source>
                {hoveredFeature && mouseCoords && (
                    <Tooltip
                        feature={hoveredFeature}
                        sx={{
                            left: `${Math.max(0, mouseCoords.x - 300)}px`,
                            top: `${mouseCoords.y + 16}px`,
                        }}
                    />
                )}
            </ReactMapGL>
            <Link
                sx={sx.link}
                href="https://www.azavea.com/"
                title="Made by Azavea"
                onClick={(e) => e.stopPropagation()}
            >
                <Image src={logo} alt="" sx={sx.logo} height={24} />
            </Link>
        </Flex>
    );
}

export default Map;
