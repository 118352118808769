/** @jsx jsx */
import { jsx, Flex } from "theme-ui";

import {
    RAMP_MORE_MEN,
    RAMP_SAME,
    RAMP_MORE_WOMEN,
    RAMP_OFF,
    FILTER_VALUES,
} from "../../constants";

const sx = {
    legend: {
        flexDirection: "column",
        mb: 2,
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        my: 1,
    },
    label: {
        flex: "auto",
        ml: 1,
        fontSize: 3,
        fontWeight: "bold",
        whiteSpace: "nowrap",
    },
    swatches: {
        flexDirection: "row",
        flex: "none",
    },
    swatch: {
        flex: "none",
        width: "4.2rem",
        height: "2.4rem",
        border: "1px solid #CECECE",
        ":not(:last-of-type)": {
            mr: "0.4rem",
        },
    },
    raiHeading: {
        alignSelf: "flex-start",
        mb: 1,
    },
    raiBuckets: {
        alignSelf: "flex-start",
        width: "22.2rem",
        textAlign: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
        fontWeight: "bold",
    },
    raiBucket: {
        width: "4.2rem",
        fontSize: 3,
        whiteSpace: "nowrap",
    },
    definedAs: {
        alignSelf: "flex-start",
        mt: 2,
        fontSize: 2,
        color: "gray",
    },
};

function Row({ label, ramp: _ramp, isOff, ...props }) {
    const ramp = isOff ? RAMP_OFF : _ramp;
    return (
        <Flex sx={{ ...sx.row, opacity: isOff ? 0.2 : 1 }} {...props}>
            <Flex sx={sx.swatches}>
                {ramp.map((color) => (
                    <span sx={{ ...sx.swatch, bg: color }} key={color}></span>
                ))}
            </Flex>
            <span sx={{ ...sx.label, fontWeight: isOff ? "normal" : "bold" }}>
                {label}
            </span>
        </Flex>
    );
}

function Legend({ filter, ...props }) {
    return (
        <Flex sx={sx.legend} {...props}>
            <div sx={sx.raiHeading}>
                % residents <strong>without</strong> road access <sup>*</sup>
            </div>
            <Flex sx={sx.raiBuckets}>
                <span sx={sx.raiBucket}>&lt;&thinsp;10%</span>
                <span sx={sx.raiBucket}>10–25</span>
                <span sx={sx.raiBucket}>25–50</span>
                <span sx={sx.raiBucket}>50–75</span>
                <span sx={sx.raiBucket}>&gt;&thinsp;75%</span>
            </Flex>
            <Row label="Area has more women" ramp={RAMP_MORE_WOMEN} />
            <Row
                label="About the same"
                ramp={RAMP_SAME}
                isOff={filter !== FILTER_VALUES.ALL_AREAS}
            />
            <Row label="Area has more men" ramp={RAMP_MORE_MEN} />
            <div sx={sx.definedAs}>
                <sup>*</sup> Defined as living &gt;&nbsp;2&thinsp;km from an
                all&#8209;season road
            </div>
        </Flex>
    );
}

export default Legend;
