const theme = {
    space: [
        0,
        "0.8rem",
        "1.6rem",
        "2.4rem",
        "3.2rem",
        "4rem",
        "4.8rem",
        "5.6rem",
        "6.4rem",
    ],
    fonts: {
        body:
            '"Source Sans Pro", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        heading: "inherit",
        monospace: '"Roboto Mono", monospace',
    },
    fontSizes: [
        "1.1rem",
        "1.2rem",
        "1.3rem",
        "1.4rem",
        "1.6rem",
        "1.8rem",
        "2rem",
        "2.8rem",
        "3.4rem",
    ],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    colors: {
        background: "#E2E3D5",
        text: "#131F35",
        gray: "rgba(0, 0, 0, 0.6)",
        primary: "#0050B8",
        divider: "rgba(0, 0, 0, 0.2)",
        link: "#0050B8",
        disabled: "#999",
        muted: "#333",
        accent: "khaki",
    },
    shadows: [
        "none",
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
        "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
        "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)",
        "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)",
    ],
    text: {
        heading: {
            color: "text",
            fontFamily: "heading",
            lineHeight: "heading",
            fontWeight: "heading",
        },
        code: {
            fontFamily: "monospace",
            bg: "tint",
            px: "0.4rem",
        },
    },
    forms: {
        radio: {
            mr: 1,
        },
    },
    buttons: {
        primary: {
            m: 0,
            px: 2,
            py: 1,
            font: "inherit",
            border: "2px solid transparent",
            borderRadius: "2px",
            bg: "text",
            color: "background",
            cursor: "pointer",
        },
        secondary: {
            variant: "buttons.primary",
            bg: "transparent",
            color: "text",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "border",
        },
        text: {
            variant: "buttons.primary",
            p: 0,
            bg: "transparent",
            color: "text",
            boxShadow: 0,
            ":hover": {
                textDecoration: "underline",
            },
            ":focus": {
                textDecoration: "underline",
            },
        },
    },
    styles: {
        root: {
            fontFamily: "body",
            lineHeight: "body",
            fontWeight: "body",
            fontSize: 4,
            position: "relative",
            width: "100%",
            m: 0,
            p: 0,
        },
        a: {
            textDecoration: "none",
            color: "accent",
        },
    },
};

export default theme;
