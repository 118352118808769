/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { Fragment } from "react";

import {
    GAP_NAME,
    RAI_NAME,
    RAMP_MORE_MEN,
    RAMP_SAME,
    RAMP_MORE_WOMEN,
} from "../constants";

const sx = {
    tooltip: {
        position: "absolute",
        m: 1,
        px: "1.2rem",
        pb: "1.2rem",
        maxWidth: "30rem",
        bg: "#F4F5ED",
        zIndex: 1,
        boxShadow: 2,
        pointerEvents: "none",
    },
    name: {
        mt: 1,
        mb: "0.4rem",
        fontSize: 5,
    },
    gap: {
        my: 0,
        fontSize: 3,
    },
    raiDetails: {
        flexDirection: "row",
        alignItems: "center",
        mt: 2,
        py: 1,
        px: 2,
    },
    rai: {
        flex: "none",
        minWidth: "1.5ch",
        fontSize: 8,
        textAlign: "right",
    },
    raiBoilerplate: {
        flex: "auto",
        ml: 1,
        fontSize: 2,
        lineHeight: 1.3,
    },
};

const capitalize = (str) =>
    `${str.toUpperCase().charAt(0)}${str.toLowerCase().slice(1)}`;

function Tooltip({ feature, ...props }) {
    const {
        [GAP_NAME]: gap,
        [RAI_NAME]: rai,
        COUNTRY,
        admin,
        ADM3_EN,
        admin1Name,
        admin2Name,
        DNAME_2010,
        SNAME_2010,
    } = feature.properties;

    const country =
        COUNTRY === "UGANDA" ? "Uganda" : admin2Name ? "Burundi" : "Rwanda";

    let name =
        country === "Uganda"
            ? `${capitalize(SNAME_2010)}, ${capitalize(DNAME_2010)}`
            : country === "Burundi"
            ? `${admin2Name}, ${admin1Name}`
            : admin || ADM3_EN;

    name = `${name}, ${country}`;

    const ramp =
        gap < -0.2 ? RAMP_MORE_MEN : gap < 0.2 ? RAMP_SAME : RAMP_MORE_WOMEN;
    const index =
        rai < 0.25 ? 4 : rai < 0.5 ? 3 : rai < 0.75 ? 2 : rai < 0.9 ? 1 : 0;
    const textColor = index < 2 ? "#000000DD" : "#FFFFFFDD";

    let gapLabel;
    if (gap < -3) {
        gapLabel = (
            <Fragment>
                <strong>many more men</strong> than women
            </Fragment>
        );
    } else if (gap < -0.2) {
        gapLabel = (
            <Fragment>
                <strong>somewhat more men</strong> than women
            </Fragment>
        );
    } else if (gap < 0.2) {
        gapLabel = (
            <Fragment>
                <strong>about the same</strong> women &amp; men
            </Fragment>
        );
    } else if (gap < 1) {
        gapLabel = (
            <Fragment>
                <strong>somewhat more women</strong> than men
            </Fragment>
        );
    } else {
        gapLabel = (
            <Fragment>
                <strong>many more women</strong> than men
            </Fragment>
        );
    }

    return (
        <aside sx={sx.tooltip} {...props}>
            <h4 sx={sx.name}>{name}</h4>
            <p sx={sx.gap}>has {gapLabel}</p>
            <Flex sx={{ ...sx.raiDetails, bg: ramp[index], color: textColor }}>
                <span sx={sx.rai}>{Math.round((1 - rai) * 1000) / 10}</span>
                <span sx={sx.raiBoilerplate}>
                    <strong>%</strong> of residents live more than 2&nbsp;km
                    from a road
                </span>
            </Flex>
        </aside>
    );
}

export default Tooltip;
