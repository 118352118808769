/** @jsx jsx */
import { jsx, Flex } from "theme-ui";

import Home from "./Home";
import Country from "./Country";
import Filter from "./Filter";
import Legend from "./Legend";

const sx = {
    sidebar: {
        minHeight: "100%",
        flexDirection: "column",
        px: 2,
        pb: 2,
        overflowY: "auto",
    },
    nav: {
        display: "flex",
        flex: "none",
        flexFlow: "row nowrap",
        justifyContent: "space-around",
        height: "6.4rem",
        mb: 4,
    },
    tab: {
        flex: "auto",
        px: "0.2rem",
        py: 1,
        borderBottom: "3px solid transparent",
        color: "gray",
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: "0.06em",
        cursor: "pointer",
        ":hover": {
            color: "text",
        },
        ":focus:not(:focus-visible)": {
            outline: "none",
        },
    },
    selectedTab: {
        borderBottomColor: "text",
        color: "text",
    },
    content: {
        flex: "auto",
        overflow: "auto",
        mb: 2,
    },
    filter: {
        flex: "none",
        mt: "auto",
        mb: 4,
    },
    legend: {
        flex: "none",
    },
};

function Tab({ name, label, onChange, isSelected, ...props }) {
    return (
        <button
            sx={{
                ...sx.tab,
                ...(isSelected ? sx.selectedTab : {}),
            }}
            onClick={() => onChange(name)}
        >
            {label}
        </button>
    );
}

function Sidebar({ view, onViewChange, filter, onFilterChange, ...props }) {
    const isHome = view === "home";

    return (
        <Flex sx={sx.sidebar} {...props}>
            <nav sx={sx.nav}>
                <Tab
                    name="home"
                    label="Home"
                    isSelected={view === "home"}
                    onChange={onViewChange}
                />
                <Tab
                    name="BDI"
                    label="Burundi"
                    isSelected={view === "BDI"}
                    onChange={onViewChange}
                />
                <Tab
                    name="RWA"
                    label="Rwanda"
                    isSelected={view === "RWA"}
                    onChange={onViewChange}
                />
                <Tab
                    name="UGA"
                    label="Uganda"
                    isSelected={view === "UGA"}
                    onChange={onViewChange}
                />
            </nav>
            {isHome ? (
                <Home sx={sx.content} />
            ) : (
                <Country country={view} sx={sx.content} />
            )}
            <Filter sx={sx.filter} filter={filter} onChange={onFilterChange} />
            <Legend sx={sx.legend} filter={filter} />
        </Flex>
    );
}

export default Sidebar;
