/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useState } from "react";

import Sidebar from "../components/Sidebar";
import Map from "../components/Map";

import { FILTER_VALUES } from "../constants";

const sx = {
    page: {
        flexDirection: "row",
        bg: "background",
    },
    sidebar: {
        flex: "none",
        width: "40rem",
    },
    map: {
        flex: "auto",
    },
};

function MapPage(props) {
    const [view, setView] = useState("home");
    const [filter, setFilter] = useState(FILTER_VALUES.ALL_AREAS);

    return (
        <Flex sx={sx.page} {...props}>
            <Sidebar
                sx={sx.sidebar}
                view={view}
                onViewChange={setView}
                filter={filter}
                onFilterChange={setFilter}
            />
            <Map sx={sx.map} view={view} filter={filter} />
        </Flex>
    );
}

export default MapPage;
