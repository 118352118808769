/** @jsx jsx */
import { jsx, Label, Radio } from "theme-ui";

import { FILTER_VALUES } from "../../constants";

const sx = {
    filter: {},
    heading: {
        my: 0,
        fontSize: 4,
        fontWeight: "bold",
    },
    label: {
        my: 1,
        cursor: "pointer",
    },
};

function Filter({ filter, onChange, ...props }) {
    return (
        <div sx={sx.filter} {...props}>
            <h4 sx={sx.heading}>Show:</h4>
            <Label sx={sx.label}>
                <Radio
                    checked={filter === FILTER_VALUES.ALL_AREAS}
                    onChange={() => onChange(FILTER_VALUES.ALL_AREAS)}
                />
                All areas
            </Label>
            <Label sx={sx.label}>
                <Radio
                    checked={filter === FILTER_VALUES.WITH_GAP}
                    onChange={() => onChange(FILTER_VALUES.WITH_GAP)}
                />
                Areas with a gender gap
            </Label>
            <Label sx={sx.label}>
                <Radio
                    checked={filter === FILTER_VALUES.WITH_BIG_GAP}
                    onChange={() => onChange(FILTER_VALUES.WITH_BIG_GAP)}
                />
                Areas with significant gender gap
            </Label>
        </div>
    );
}

export default Filter;
